import React, { useState } from 'react';
import FormSVG from '@plone/volto/icons/form.svg';
import { flattenToAppURL } from '@plone/volto/helpers';
import { FormFieldWrapper } from '@plone/volto/components';
import { SidebarPortal } from '@plone/volto/components';
import { Form, Input, TextArea, Checkbox, Message, Container, Divider, Label, Segment, Button } from 'semantic-ui-react';

import Subscription from './Subscription';

const CustomBlockDataForm = ({ data, block, onChangeBlock }) => {
    const handleChange = (id, value) => {
        onChangeBlock(block, {
            ...data,
            [id]: value,
        });
    };

    return (
        <Form>
            <FormFieldWrapper id="listmonk-fields-input" title="Listmonk Fields">
                <TextArea
                    name="listmonkFields"
                    value={data.listmonkFields || ''}
                    onChange={(e, { value }) => handleChange('listmonkFields', value)}
                    placeholder="Paste everything between <div>..</div>"
                />
            </FormFieldWrapper>
        </Form>
    );
};


const ListMonk = (props) => {
    const { data, onChangeBlock, block, selected = false, location } = props;
    const listmonkFields = extractListMonkInfo(data['listmonkFields']);

    const [formData, setFormData] = useState({
        email: '',
        name: '',
        lists: listmonkFields.filter((field) => field.checked).map((field) => field.value), // Initialize with default checked values
    });

    const [responseMessage, setResponseMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [showForm, setShowForm] = useState(true);

    if (props?.pathname?.endsWith('/edit')) {
        return (
            <>
                <>EDIT FORM</>
                <SidebarPortal selected={selected}>
                    <CustomBlockDataForm data={data} block={block} onChangeBlock={onChangeBlock} />
                </SidebarPortal>
            </>
        );
    }

    const resetForm = () => {
        setFormData({
            email: '',
            name: '',
            lists: listmonkFields.filter((field) => field.checked).map((field) => field.value),
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const payload = {
            email: formData.email,
            name: formData.name || '',
            status: 'enabled',
            lists: formData.lists,
        };

        try {
            const response = await fetch('/listmonk', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload),
            });

            const responseData = await response.json();
            if (response.ok) {
                setResponseMessage(responseData?.message || 'Subscriber created successfully!');
                setErrorMessage(null);
                setShowForm(false);
                resetForm();
            } else {
                setResponseMessage(null);
                setErrorMessage(responseData?.message || 'Failed to create subscriber');
            }

        } catch (error) {
            setResponseMessage(null);
            setErrorMessage('An error occurred while creating the subscriber.');
            console.error('Error:', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (type === 'checkbox') {
            setFormData((prev) => ({
                ...prev,
                lists: checked
                    ? [...prev.lists, value]
                    : prev.lists.filter((list) => list !== value),
            }));
        } else {
            setFormData((prev) => ({ ...prev, [name]: value }));
        }
    };

    const handleCheckAll = () => {
        setFormData((prev) => ({
            ...prev,
            lists: listmonkFields.map((field) => field.value),
        }));
    };

    const handleUncheckAll = () => {
        setFormData((prev) => ({ ...prev, lists: [] }));
    };

    const handleStartOver = () => {
        setShowForm(true);
        setResponseMessage(null);
        setErrorMessage(null);
    };

    if (responseMessage && !showForm) {
        return (
            <Segment padded>
                <Message style={{
                    fontSize: "medium",
                    borderRadius: "5px",
                    borderLeft: "16px solid #c2c2c2"
                }}>
                    <Message.Header>Success</Message.Header>
                    <p>{responseMessage}</p>
                </Message>
                <Button onClick={handleStartOver}>Start Over</Button>
            </Segment>
        );
    }

    return (
        <Segment padded>
            <Form onSubmit={handleSubmit}>
                <Form.Field inline required style={{ display: 'flex', alignItems: 'baseline' }}>
                    <label htmlFor="email" style={{ width: '25%' }}>Your E-Mail Address</label>
                    <Input
                        type="email"
                        name="email"
                        required
                        placeholder="E-mail"
                        onChange={handleInputChange}
                        value={formData.email}
                        style={{ flex: 1 }}
                    />
                </Form.Field>
                <Divider />
                <Form.Field inline style={{ display: 'flex', alignItems: 'baseline' }}>
                    <label htmlFor="name" style={{ width: '25%' }}>Your Name</label>
                    <Input
                        type="text"
                        name="name"
                        placeholder="Name (optional)"
                        onChange={handleInputChange}
                        value={formData.name}
                        style={{ flex: 1 }}
                    />
                </Form.Field>
                <Divider />
                <Form.Field required inline style={{ display: 'flex', alignItems: 'baseline' }}>
                    <label style={{ width: '25%' }}>Select Notification Topics</label>
                    <div style={{ flex: 1 }}>
                        <div style={{ marginBottom: '1rem' }}>
                            <Button type="button" size="tiny" onClick={handleCheckAll}>Check All</Button>
                            <Button type="button" size="tiny" onClick={handleUncheckAll}>Uncheck All</Button>
                        </div>
                        {listmonkFields.map((checkbox, index) => (
                            <Form.Field key={index}>
                                <Checkbox
                                    id={checkbox.id}
                                    name="lists"
                                    value={checkbox.value}
                                    label={checkbox.label}
                                    checked={formData.lists.includes(checkbox.value)}
                                    onChange={handleInputChange}
                                />
                            </Form.Field>
                        ))}
                    </div>
                </Form.Field>
                <Divider />
                <Form.Button>
                    Submit
                </Form.Button>
            </Form>

            {errorMessage && (
                <Message negative
                    style={{
                        fontSize: "medium",
                        borderRadius: "5px",
                        borderLeft: "16px solid #cc8b8b"
                    }}>
                    <Message.Header>Error</Message.Header>
                    <p>{errorMessage}</p>
                </Message>
            )}
        </Segment>
    );
};


const InstallListMonk = (config) => {
    config.blocks.blocksConfig.InstallListMonk = {
        id: 'InstallListMonk',
        title: 'List Monk',
        group: 'common',
        icon: FormSVG,
        view: ListMonk,
        edit: ListMonk,
        restricted: false,
        mostUsed: false,
        sidebarTab: 1,
        security: {
            addPermission: [],
            view: [],
        },
    };
};

function extractListMonkInfo(htmlString) {
    const checkboxesWithLabels = [];

    try {
        // Regular expression to match input checkboxes with their attributes
        const checkboxRegex = /<input\s+[^>]*type="checkbox"[^>]*>/gi;
        const labelRegex = /<label\s+for="([^"]+)">([^<]+)<\/label>/gi;

        // Extract all checkbox inputs
        const checkboxes = htmlString.match(checkboxRegex) || [];

        checkboxes.forEach((checkbox) => {
            const idMatch = /id="([^"]+)"/i.exec(checkbox);
            const nameMatch = /name="([^"]+)"/i.exec(checkbox);
            const valueMatch = /value="([^"]+)"/i.exec(checkbox);
            const checkedMatch = /checked/i.test(checkbox);

            if (idMatch) {
                const id = idMatch[1];
                const labelMatch = new RegExp(`<label\\s+for="${id}">([^<]+)<\\/label>`, 'i').exec(htmlString);
                const label = labelMatch ? labelMatch[1].trim() : '';

                checkboxesWithLabels.push({
                    id,
                    name: nameMatch ? nameMatch[1] : '',
                    value: valueMatch ? valueMatch[1] : '',
                    checked: checkedMatch,
                    label,
                });
            }
        });
    } catch (error) {
        console.log(error);
    }

    return checkboxesWithLabels;
}

export { InstallListMonk, Subscription };
