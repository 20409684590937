import fetch from 'node-fetch';
import config from '@plone/volto/registry';


export default async function (req, res, next) {


    if (!config.settings?.listmonkAPI) {
        return res.status(500).json({
            message: 'Listmonk API URL is not configured.',
            error: 'Configuration error.'
        });
    }

    const LISTMONK_API_URL = `${config.settings.listmonkAPI}/api/public/subscription`;

    try {

        const { email, name, lists } = req.body;

        if (!email) {
            return res.status(400).json({
                message: 'Email field is required.',
                error: 'Missing fields.'
            });
        }

        if (!email || !name || lists?.length === 0) {
            return res.status(400).json({
                message: 'At least one notification topic must be selected before you can continue.',
                error: 'Missing fields.'
            });
        }

        const payload = {
            email,
            name,
            list_uuids: Array.isArray(lists) ? lists : [],
        };

        const response = await fetch(LISTMONK_API_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        });

        const responseData = await response.json();

        if (!response.ok) {
            return res.status(response.status).json({
                message: 'Something went wrong. Please try again later.',
                error: responseData
            });
        }

        res.status(200).json({
            message: 'Your subscription has been successfully confirmed. Future updates will be sent to your registered email address.',
            data: responseData.data
        });

    } catch (error) {
        console.error('Error while sending data to listmonk:', error.message);
        res.status(500).json({
            message: 'Something went wrong. Please try again later.',
            error: error.message
        });
    }

}