import express from 'express';


// import { pathReplacerMiddleware, pathReplacerMiddlewareID, pathReplacerMiddlewarePath } from './pathReplacer';

// const pathReplacerMiddleware = function (req, res, next) {
//     // Define the regex test and replacer
//     const replacements = [
//         {
//             test: /\/@@images\/.+\.png$/,   // Regex to match the path
//             replacer: "/@@images/image"     // Replacement string
//         }
//     ];

//     // Iterate over the replacements
//     replacements.forEach(({ test, replacer }) => {
//         if (test.test(req.url)) {
//             req.url = req.url.replace(test, replacer);
//         }
//     });

//     next();
// };

// const pathReplacerMiddlewareID = "path-replacer-middleware";
// const pathReplacerMiddlewarePath = "*";

// export default function middlewareInstall(config) {
//     if (__SERVER__) {
//         const express = require('express');
//         const middleware = express.Router();
//         middleware.all(pathReplacerMiddlewarePath, pathReplacerMiddleware);
//         middleware.id = pathReplacerMiddlewareID;
//         config.settings.expressMiddleware.unshift(middleware);
//     }
// }

export const setupServerMiddleware = (id, path, callback, options = {}) => {
    if (__SERVER__) {
        const express = require('express');
        const middleware = express.Router();

        // Apply body-parsing middleware only if specified in options
        if (options.parseJson) {
            middleware.use(express.json());
        }
        if (options.parseUrlEncoded) {
            middleware.use(express.urlencoded({ extended: true }));
        }

        middleware.all(path, callback);
        middleware.id = id;
        return middleware
    }
}